/* eslint-disable max-len */
import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { Phone } from '../../catalog/products/equipement/complex/phone';
import { CartService } from '../../checkout/cart/cart.service';
import { Scheme } from '../../checkout/cart/scheme.class';
import { ModalUpdateWarningComponent } from './modal-update-warning/modal-update-warning.component';
import {
  CommercialProposal,
  FundingEnum,
  FundingProduct,
  FundingScheme,
  FundingStatus,
  GetCurrentFundingModeApiResult,
  IPostFundingResponse,
} from '../interfaces/funding.interface';
import { FundingCart } from '../models/funding.cart';
import { FundingCurrent } from '../models/funding.current';
import { FundingMethod } from '../models/funding.method';
import { FundingRepository } from '../repositories/funding.repository';
import { FundingErrorService } from './funding-error.service';

@Injectable({
  providedIn: 'root',
})
export class FundingService {
  public eligibleFundingCart: FundingCart;
  public fundingCurrent: FundingCurrent;
  public stateCredit: EventEmitter<boolean> = new EventEmitter<boolean>();
  public stateEligibleFunding: EventEmitter<void> = new EventEmitter<void>();
  public statePostedFunding: EventEmitter<void> = new EventEmitter<void>();
  public bestProposal: CommercialProposal;
  // on ne sait pas pq ça ne marche pas si on met Subject<number | null>(); todo @Ning
  public selectedNbMonth$$ = new BehaviorSubject<number | null>(null);
  public switchToCash$: Observable<void>;

  public readonly selectedProposal$$ = new BehaviorSubject<CommercialProposal>(null);
  private readonly switchToCash$$ = new Subject<void>();

  constructor(
    private fundingRepo: FundingRepository,
    private fundingErrorService: FundingErrorService,
    private modalService: NgbModal,
  ) {
    combineLatest([this.stateCredit, this.selectedNbMonth$$]).subscribe(isOnCredit => {
      if (!isOnCredit) {
        return;
      }
      const nbLoan = this.selectedNbMonth$$.getValue();
      const commercialProposal = this.getBestProposalByNbMonth(nbLoan);
      if (!nbLoan || !commercialProposal) {
        return;
      }
      this.selectedProposal$$.next(commercialProposal);
    });

    this.switchToCash$ = this.switchToCash$$.asObservable();
  }

  public static extractNbMonths(type: FundingEnum): number {
    switch (type) {
      case FundingEnum.younitedBy3:
        return 3;
      case FundingEnum.younitedBy12:
        return 12;
      case FundingEnum.younitedBy24:
        return 24;
      case FundingEnum.younitedBy36:
        return 36;
      default:
        return 0;
    }
  }

  public getEligibleFundingModes(cartId: number): Observable<FundingCart> {
    return this.fundingRepo.getFundingModes(cartId).pipe(
      //  shall we remove catchError?
      catchError(() => of(this.eligibleFundingCart)),
      map((fundingCart: FundingCart) => {
        this.eligibleFundingCart = fundingCart;
        this.bestProposal = this.getBestProposals();
        if (!this.bestProposal) {
          this.selectedProposal$$.next(null);
        }
        this.stateEligibleFunding.next();
        return this.eligibleFundingCart;
      }),
    );
  }

  public addGrantedCreditIntoEligibleFundingMode(): void {
    this.eligibleFundingCart.fundingMethods.push({
      type: this.fundingCurrent.modeDeFinancement,
      commercialProposal: [this.fundingCurrent.commercialProposal],
      bestCommercialProposal: this.fundingCurrent.commercialProposal,
    } as FundingMethod);
    this.bestProposal = this.getBestProposals();
  }

  public getCurrentFundingModes(cartId: number): Observable<IPostFundingResponse> {
    return this.fundingRepo.getCurrentFundingModes(cartId).pipe(
      catchError(() => of(null)),
      tap((data: IPostFundingResponse) => {
        if (data) {
          this.fundingCurrent = this.fundingRepo.createCurrentFundingCartModel(
            data as unknown as GetCurrentFundingModeApiResult,
          );
        }
        this.statePostedFunding.next();
      }),
    );
  }

  public getCurrentFundingMode(cartId: number): Observable<FundingCurrent> {
    return this.fundingRepo.getCurrentFundingMode(cartId).pipe(
      // why ?
      catchError(() => of(this.fundingCurrent)),
      map((fundingCart: FundingCurrent) => {
        this.fundingCurrent = fundingCart;
        this.statePostedFunding.next();
        return this.fundingCurrent;
      }),
    );
  }

  public postFundingMode(
    cartService: CartService,
    forceCashFundingMode: boolean = false,
    isPostFromSummaryResolver = false,
  ): Observable<IPostFundingResponse> {
    if (
      (window.location.href.includes('pieces-justificatives-v2') &&
        ['YOUNITED_X3', 'YOUNITED_X12', 'YOUNITED_X24', 'YOUNITED_X36'].includes(cartService.cart.creditData?.type)) ||
      ((window.location.href.includes('pieces-justificatives-v2') || window.location.href.includes('recapitulatif')) &&
        !['YOUNITED_X3', 'YOUNITED_X12', 'YOUNITED_X24', 'YOUNITED_X36'].includes(cartService.cart.creditData?.type)) ||
      isPostFromSummaryResolver
    ) {
      return this.fundingRepo.postFundingMode(cartService, forceCashFundingMode).pipe(
        catchError(() => {
          this.showFundingErrorDialog();
          return of(null);
        }),
        tap(data => {
          if (data) {
            this.fundingCurrent = this.fundingRepo.createCurrentFundingCartModel(data);
          }
          this.statePostedFunding.next();
        }),
      );
    }
    return of(null);
  }

  public getFundingYounitedForCart(): FundingMethod[] {
    if (this.hasGrantedCredit()) {
      return [
        {
          type: this.fundingCurrent.modeDeFinancement,
          commercialProposal: [this.fundingCurrent.commercialProposal],
          bestCommercialProposal: this.fundingCurrent.commercialProposal,
        } as FundingMethod,
      ];
    }
    if (!this.eligibleFundingCart?.fundingMethods?.length) {
      return [];
    }
    return this.eligibleFundingCart.fundingMethods.filter((fundingMethod: FundingMethod) =>
      this.fundingRepo.fundingYounitedKeys.includes(fundingMethod.type),
    );
  }

  public isSchemeEligibleEdp(scheme: Scheme): boolean {
    const phone: Phone = scheme.getProductByType(Phone);
    if (!phone) {
      return false;
    }
    const fundingScheme = this.eligibleFundingCart?.fundingSchemes.find(
      (fs: FundingScheme) => fs.idScheme === scheme.quoteId.toString(),
    );
    if (!fundingScheme) {
      return false;
    }
    const fundingProduct: FundingProduct = fundingScheme.products.find(
      (fp: FundingProduct) => fp.idProduct === phone.itemId.toString(),
    );
    if (!fundingProduct) {
      return false;
    }
    return fundingProduct.productsFundingMode.some((mode: FundingMethod) => mode.type === FundingEnum.edp);
  }

  public getEdpFundingData(scheme: Scheme): CommercialProposal {
    const phone: Phone = scheme.getProductByType(Phone);
    if (!phone) {
      return null;
    }
    const fundingScheme = this.eligibleFundingCart?.fundingSchemes.find(
      (fs: FundingScheme) => '' + fs.idScheme === '' + scheme.quoteId,
    );
    if (!fundingScheme) {
      return null;
    }
    const fundingProduct: FundingProduct = fundingScheme.products.find(
      (fp: FundingProduct) => '' + fp.idProduct === '' + phone.itemId,
    );
    if (!fundingProduct) {
      return null;
    }
    const fundingMode = fundingProduct.productsFundingMode.find((mode: FundingMethod) => mode.type === FundingEnum.edp);
    if (!fundingMode) {
      return null;
    }
    return fundingMode.commercialProposal[0];
  }

  public getBestProposalByNbMonth(nbLoan: number): CommercialProposal {
    if (!this.eligibleFundingCart?.fundingMethods.length) {
      return null;
    }
    // verification si le mode de financement est un credit octroyé
    if (
      this.fundingCurrent &&
      this.fundingCurrent.status === FundingStatus.ok &&
      [
        FundingEnum.younited,
        FundingEnum.younitedBy3,
        FundingEnum.younitedBy12,
        FundingEnum.younitedBy24,
        FundingEnum.younitedBy36,
      ].indexOf(this.fundingCurrent.modeDeFinancement) !== -1
    ) {
      return this.fundingCurrent.commercialProposal;
    }
    if (!this.getFundingYounitedForCart().length) {
      return null;
    }
    return this.getFundingYounitedForCart().filter(data => data.bestCommercialProposal.nbLoan === nbLoan)[0]
      .bestCommercialProposal;
  }

  public hasGrantedCredit(): boolean {
    return (
      [
        FundingEnum.younited,
        FundingEnum.younitedBy3,
        FundingEnum.younitedBy12,
        FundingEnum.younitedBy24,
        FundingEnum.younitedBy36,
      ].indexOf(this.fundingCurrent?.modeDeFinancement) !== -1 && this.fundingCurrent.status === FundingStatus.ok
    );
  }

  public launchWarningModal(callbackFunction, ...args): void {
    if (this.hasGrantedCredit()) {
      const options: NgbModalOptions = <NgbModalOptions>{
        backdrop: 'static',
        size: 'lg',
        backdropClass: 'semi-opacity',
        windowClass: 'modal-update-warning',
        keyboard: false,
      };
      const component = this.modalService.open(ModalUpdateWarningComponent, options).componentInstance;
      component.continue = (): void => {
        component.close();
        callbackFunction(...args);
      };
    } else {
      callbackFunction(...args);
    }
  }

  public launchWarningModalWithCallBackObservable(
    cartService: CartService,
    callbackFunction: (...argss) => Observable<unknown>,
    ...args
  ): void {
    if (this.hasGrantedCredit()) {
      const options: NgbModalOptions = <NgbModalOptions>{
        backdrop: 'static',
        size: 'lg',
        backdropClass: 'semi-opacity',
        windowClass: 'modal-update-warning',
        keyboard: false,
      };
      const component = this.modalService.open(ModalUpdateWarningComponent, options).componentInstance;
      component.continue = (): void => {
        component.close();
        callbackFunction(...args).subscribe();
      };
    } else {
      callbackFunction(...args)
        .pipe(
          concatMap(() => this.postFundingMode(cartService)),
          concatMap(() => cartService.refreshCart()),
        )
        .subscribe();
    }
  }

  private getBestProposals(): CommercialProposal {
    const fundingsMethod: FundingMethod[] = this.getFundingYounitedForCart();
    const proposals: CommercialProposal[] = fundingsMethod.reduce((acc, cur) => acc.concat(cur.commercialProposal), []);
    return proposals.length === 0 ? undefined : proposals[0];
  }

  private showFundingErrorDialog(): void {
    const fundingErrorComponent = this.fundingErrorService.buildFundingErrorComponent();

    fundingErrorComponent.switchToCash = (): void => {
      this.switchToCash$$.next();
      fundingErrorComponent.closeModal();
    };
  }
}
